<template>
  <div class="users">
    <router-link :to="{ name: 'Skills' }">
      All Skills
    </router-link>
    <h2>Search applicants by...</h2>

    <div v-if="userCanSearch">
      <b-tabs
        v-model="tabIndex"
        align="center"
        pills
        small
      >
        <applicant-search-tab
          :search-data="getUserSearchData()"
          :tab-active="selectedSearch() === SEARCH_TYPES.USER"
          @startSearch="startSearch"
        />

        <applicant-search-tab
          :search-data="getSkillsSearchData()"
          :tab-active="selectedSearch() === SEARCH_TYPES.SKILLS"
          @startSearch="startSearch"
        />

        <applicant-search-tab
          :search-data="getUserNoSkillsSearchData()"
          :tab-active="selectedSearch() === SEARCH_TYPES.NOSKILLS"
          @startSearch="startSearch"
        />
      </b-tabs>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { storeToRefs, mapActions } from "pinia"
import { useStore } from "@/store"

import ApplicantSearchTab from "@/components/ApplicantSearchTab"
import { SEARCH_TYPES } from "@/utils/constants"

const CancelToken = axios.CancelToken

export default {
  name: "Applicants",

  components: {
    ApplicantSearchTab
  },

  props: {},
  setup() {
    const store = useStore()
    const { userIsStaffingTeamMember, userIsRecruiter, personSearchProperties, noSkillsSearchProperties, skillsSearchProperties, searchType } = storeToRefs(store)
    return { userIsStaffingTeamMember, userIsRecruiter, personSearchProperties, noSkillsSearchProperties, skillsSearchProperties, searchType }
  },
  data() {
    // Common search data cloned to *SearchData with spread operator
    const commonSearchData = {
      succeeded: true,
      lastRequestError: null,
      searching: false,
      searchResults: [],
      haveSearched: false,
      cancelSearch: null
    }
    return {
      SEARCH_TYPES: SEARCH_TYPES,
      userSearchData: {
        searchType: SEARCH_TYPES.USER,
        apiPath: "/users",
        tabTitle: "Name or Email",
        subtitle: "by name or email",
        ...commonSearchData
      },
      skillSearchData: {
        searchType: SEARCH_TYPES.SKILLS,
        apiPath: "/users/skillsSearch",
        tabTitle: "Skills",
        subtitle: "by skill",
        ...commonSearchData
      },
      userNoSkillsSearchData: {
        searchType: SEARCH_TYPES.NOSKILLS,
        apiPath: "/users",
        tabTitle: "No Skills Entered",
        subtitle: "with no skills",
        ...commonSearchData
      },
      user: {},

      tabIndex: 0
    }
  },

  computed: {
    userCanSearch() {
      return this.userIsStaffingTeamMember || this.userIsRecruiter
    },

    userCanAddApplicant() {
      return this.userIsRecruiter
    }
  },

  methods: {
    ...mapActions(useStore, ['setPersonSearch', 'setSkillsSearch', 'setNoSkillsSearch', 'setSearchType']),

    arrayToSearchParams(paramName, values) {
      return values.join("&" + paramName + "=")
    },

    search(searchData, criteria, resultFunc) {
      searchData.haveSearched = true

      if (searchData.cancelSearch) {
        searchData.cancelSearch()
        searchData.cancelSearch = null
        console.log(`${searchData.apiPath}: cancelling previous search`)
      }

      searchData.searching = true
      searchData.searchResults = []

      this.$http.get(searchData.apiPath, {
        params: criteria,
        cancelToken: new CancelToken((c) => {
          searchData.cancelSearch = c
        })
      })
        .then((data) => {
          searchData.succeeded = true
          searchData.lastRequestError = null
          searchData.searchResults = data?.data
          switch (searchData.searchType) {
            case SEARCH_TYPES.USER:
              this.setPersonSearch({
                query: criteria.get("text"),
                statusBoxes: criteria.getAll("status"),
                results: data?.data
              })
              break

            case SEARCH_TYPES.SKILLS:
              this.setSkillsSearch({
                query: criteria.get("text"),
                statusBoxes: criteria.getAll("status"),
                skill_level: criteria.getAll("sl"),
                results: data?.data
              })
              break

            case SEARCH_TYPES.NOSKILLS:
              this.setNoSkillsSearch({
                statusBoxes: criteria.getAll("status"),
                results: data?.data
              })
              break
          }
          if (resultFunc) {
            resultFunc(data)
          }
        })
        .catch((error) => {
          const cancelledRequest = error && true === error.__CANCEL__

          if (!cancelledRequest) {
            searchData.succeeded = false
            searchData.lastRequestError = error
            console.log(error)
          } else {
            console.log("cancelled search for " + searchData.apiPath)
          }
        })
        .finally(() => {
          searchData.cancelSearch = null
          searchData.searching = false
        })
    },

    startSearch(searchData, event) {
      switch (searchData.searchType) {
        case SEARCH_TYPES.USER:
          this.startPersonSearch(event)
          this.setSearchType(searchData.searchType)
          break
        case SEARCH_TYPES.NOSKILLS:
          this.startPersonNoSkillsSearch(event)
          this.setSearchType(searchData.searchType)
          break
        case SEARCH_TYPES.SKILLS:
          this.startSkillSearch(event)
          this.setSearchType(searchData.searchType)
          break
        default:
          console.log(`Search Type ${searchData.searchType} not supported.`)
          break
      }
    },

    startPersonSearch(event) {
      let criteria = new URLSearchParams()

      if (event.statuses && 0 < event.statuses.length) {
        event.statuses.forEach((s) => {
          criteria.append("status", s)
        })
      }

      if (event.searchText && event.searchText !== "") {
        criteria.append("text", event.searchText)
      }

      this.search(this.userSearchData, criteria, () => {})
    },

    startPersonNoSkillsSearch(event) {
      let criteria = new URLSearchParams()

      criteria.append("noSkills", "1")

      if (event.statuses && 0 < event.statuses.length) {
        event.statuses.forEach((s) => {
          criteria.append("status", s)
        })
      }

      this.search(this.userNoSkillsSearchData, criteria, () => {})
    },

    startSkillSearch(event) {
      let criteria = new URLSearchParams()
      if (event.statuses && 0 < event.statuses.length) {
        event.statuses.forEach((s) => {
          criteria.append("status", s)
        })
      }
      if (event.searchText && event.searchText !== "") {
        event.searchText
          .split(",")
          .map((skill) => skill.trim() + ">=" + event.level.id)
          .forEach((skillLevel) => {
            criteria.append("sl", skillLevel)
          })

        this.search(this.skillSearchData, criteria, () => {})
      } else {
        this.startPersonSearch({})
      }
    },

    getUserSearchData() {
      this.userSearchData.searchResults = this.personSearchProperties.results || []
      this.userSearchData.haveSearched = !!this.personSearchProperties.results

      return this.userSearchData
    },
    getUserNoSkillsSearchData() {
      this.userNoSkillsSearchData.searchResults = this.noSkillsSearchProperties.results || []
      this.userNoSkillsSearchData.haveSearched = !!this.noSkillsSearchProperties.results

      return this.userNoSkillsSearchData
    },
    getSkillsSearchData() {
      this.skillSearchData.searchResults = this.skillsSearchProperties.results || []
      this.skillSearchData.haveSearched = !!this.skillsSearchProperties.results

      return this.skillSearchData
    },
    selectedSearch() {
      return this.searchType || SEARCH_TYPES.SKILLS
    }
  }
}
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.category {
  width: 80px;
}

.skill {
  min-width: 80px;
}

.level {
  width: 120px;
}

.notes {
  min-width: 200px;
}

.categoryCell {
  font-weight: bold;
}

table,
th,
td {
  border: 1px solid black;
}

td {
  padding: 2px;
}

#add-applicant-button {
  margin: 5px;
}

#any-status-checkbox {
  padding: 5px;
}

#standard-statuses-checkboxes {
  padding: 5px;
}
</style>
