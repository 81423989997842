// Pinia store
// See https://pinia.vuejs.org/ for more information on Pinia

import { defineStore } from 'pinia'
import {currentSession} from '@/utils/auth.js'
import { getCurrentUser } from 'aws-amplify/auth';
const STAFFING_OR_RECRUITERS = ['StaffingTeam', 'Recruiters']

export const useStore = defineStore('main', {
  state: () => ({
    userSession: null,
    selectedSearch: "users",
    personSearch: {
      query: "",
      statusBoxes: ["Available"],
      results: null
    },
    skillsSearch: {
      query: "",
      statusBoxes: ["Available"],
      skillLevel: ["No Experience"],
      results: null
    },
    noSkillsSearch: {
      statusBoxes: ["Available"],
      results: null
    }
  }),

  getters: {
    session: (state) => state.userSession,
    userId: (state) => state.userSession ? state.userSession.idToken.payload["cognito:username"] : null,
    userEmail: (state) => state.userSession ? state.userSession.idToken.payload["email"] : null,
    signedIn: (state) => {
      return state.userSession !== null
    },
    userIsStaffingTeamMember: (state) => state.userSession?.idToken?.payload["cognito:groups"]?.includes("StaffingTeam") || false,
    userIsApplicant: (state) => state.userSession?.idToken?.payload["cognito:groups"]?.includes("Applicants") || false,
    userIsRecruiter: (state) => state.userSession?.idToken?.payload["cognito:groups"]?.includes("Recruiters") || false,
    userIsAnler: (state) => !!state.userSession && STAFFING_OR_RECRUITERS.some(group => state.userSession.idToken.payload["cognito:groups"]?.includes(group)),
    userIsInAnyGroup: (state) => !!state.userSession && !!state.userSession.idToken.payload['cognito:groups']?.length,
    UserInGroup: (state) => (group) => state.userSession?.idToken?.payload["cognito:groups"]?.includes(group) || false,
    UserInOneOfGroups: (state) => (groups) => {
      groups = groups || []
      const userGroups = state.userSession?.idToken?.payload["cognito:groups"] || []
      return groups.filter((group) => userGroups.includes(group)).length > 0
    },
    searchType: (state) => state.selectedSearch,
    personSearchProperties: (state) => state.personSearch,
    skillsSearchProperties: (state) => state.skillsSearch,
    noSkillsSearchProperties: (state) => state.noSkillsSearch
  },

  actions: {
    setUserSession(userSession) {
      if (userSession && userSession.idToken) {
        this.$patch({ userSession: Object.assign({}, userSession) });
        console.log("User session set in store:", userSession);
      } else {
        console.warn("Attempted to set invalid user session:", userSession);
      }
    },
    clearUserSession() {
      this.$patch({ userSession: null })
    },
    setSearchType(searchType) {
      this.selectedSearch = searchType
    },
    setPersonSearch(payload) {
      this.personSearch.query = payload.query
      this.personSearch.statusBoxes = payload.statusBoxes
      this.personSearch.results = payload.results
    },
    setSkillsSearch(payload) {
      this.skillsSearch.query = payload.query
      this.skillsSearch.statusBoxes = payload.statusBoxes
      this.skillsSearch.skillLevel = payload.skill_level
      this.skillsSearch.results = payload.results
    },
    setNoSkillsSearch(payload) {
      this.noSkillsSearch.statusBoxes = payload.statusBoxes
      this.noSkillsSearch.results = payload.results
    },
    async fetchUserSession() {
      try {
        const session = await currentSession()
        if (session && session.idToken) {
          this.setUserSession(session)
          console.log("User session fetched and stored:", session)
        } else {
          console.log("No valid session found")
          this.clearUserSession()
        }
      } catch (err) {
        console.error("fetchUserSession error:", err)
        this.clearUserSession()
      }
    },
  },

  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  },
})

export default useStore